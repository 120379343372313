import React from 'react';

export default function InputText(props) {
  const { label, name, id, myStyle, onChange, onKeyDown, value } = props;
  return (
    <div className={name}>
      <label htmlFor={name} className={myStyle}>
        {label}
      </label>{' '}
      <br />
      <input
        name={name}
        type="text"
        id={id}
        onChange={onChange}
        onKeyDown={onKeyDown}
        defaultValue={value}
      />
    </div>
  );
}
