import axios from "axios";
import { getToken } from "./auth";

const success = ({ data }) => data;
const fail = err => {
  switch (err.status) {
    case 400:
      console.error(
        "API error: invalid parameters. Probably means you provided some sort of invalid character to the API or that you' not providing something it is expecting."
      );
      break;
    case 401:
      console.error(
        "API error: insufficeint credentials. :("
      );
      break;
    case 403:
      console.error(
        "API error: insufficient access. Not sure how you saw this since none of this should have privileged access. :("
      );
      break;
    case 404:
      console.error(
        "API error: endpoint not found."
      );
      break;
    default:
      console.error(
        "API error: Unknown error."
      );
      break;      
  }

  return err;
};


const api = axios.create({
  baseURL: (process.env.REACT_APP_ENDPOINT || "http://localhost:8080/v1")
});

api.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

const makeRequest = (path, method = 'get', params = {}) => {
  if (method === 'get') {
    return api
      .get(`${path}`, {params})
      .then(success, fail);
  }
  if (method === 'post') {
    return api
      .post(`${path}`, params)    
      .then(success, fail);
  }
};


export default api;