import React, { useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import api from "../../services/api";
import { setToken, isHotel } from "../../services/auth";
import InputText from "../myComponents/InputText";
import Password from "../myComponents/Password";
import Button from "../myComponents/Button";
import BigLogo from "../../assets/qrtech-logo.png";
import MessagesContext from "../../MessagesContext";

import "../../styles/App.css";
import "../../styles/pages/Login.css";

export default function Login() {
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [messages, setMessages] = useContext(MessagesContext);
  const history = useHistory();

  const handleSubmit = () => {
    setMessages({ login: "" });
    setErrorMessage(null);

    if (username === null || username.length === 0) {
      setErrorMessage("* O campo usuário não foi preenchido");
      return;
    }

    if (password === null || password.length < 6) {
      setErrorMessage("* Sua senha deve conter pelo menos 6 caracteres");
      return;
    }

    api
      .post("/auth/login", {
        username: username,
        password: password,
      })
      .then((res) => {
        if (res.status === 200) {
          setToken(res.data.token);
          if (isHotel()) {
            history.push("/dashboard"); // this will only work if the user type is "hotel"
          } else {
            history.push("/dashboard-outros"); // this will only work if the user type is "hotel"
          }
          return;
        }
        if (res.status === 202) {
          setErrorMessage(res.data);
          return;
        }
      })
      .catch((err) => {
        // console.log(err.response);
        // let res = err.response
        // if (res.status === 401) {
        //   setErrorMessage("Sua conta está desativada, contate o administrador.")
        //   return;
        // }
        setErrorMessage("* Usuário ou senha inválidos, tente novamente.");
      });
  };

  const handleUserChange = (e) => setUsername(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);

  const handlePasswordKeyDown = (e) => {
    if (e.key === "Enter") handleSubmit();
  };

  return (
    <div id="app">
      <div id="container">
        <div id="logo">
          <img src={BigLogo} alt="qrtech" className="bigLogo" />
        </div>
        {messages.login && (
          <div className="login-message">
            <span>{messages.login}</span>
          </div>
        )}
        <div id="user-password">
          <InputText
            label="Usuário"
            name="username"
            myStyle="labelLogin"
            id="username"
            onChange={handleUserChange}
          />
          <Password
            label="Senha"
            name="password"
            myStyle="labelLogin"
            id="login-password"
            onChange={handlePasswordChange}
            onKeyDown={handlePasswordKeyDown}
          />
          <Link to="/user-confirm" className="forgot-password">
            Esqueci minha senha
          </Link>
          {errorMessage && (
            <div className="login-error-message">
              <span>{errorMessage}</span>
            </div>
          )}
        </div>
        <div id="buttons">
          <Button
            label="Login"
            id="login"
            className="btn"
            handleClick={handleSubmit}
          />
          <Link to="/sign-up" className="btn-link">
            Criar Conta
          </Link>
        </div>
      </div>
    </div>
  );
}
