import React, { Suspense, lazy, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { isAuthenticated, isHotel } from './services/auth';
import Login from './components/pages/Login';
import DashboardContext from './DashboardContext';
import DashboardOutrosContext from './DashboardOutrosContext';
import DashboardPerfilContext from './DashboardPerfilContext';
import DashboardAnualContext from './DashboardAnualContext';
import DateContext from './DateContext';
import MessagesContext from './MessagesContext';

const retry = (fn, retriesLeft = 5, interval = 1000) => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
};
const SignUp = lazy(() =>
  retry(() => import('./components/pages/SignUp/SignUp'))
);

const DashboardHotel = lazy(() =>
  retry(() => import('./components/pages/Dashboard/DashboardHotel'))
);
const DashboardPeriod = lazy(() =>
  retry(() => import('./components/pages/Dashboard/DashboardPeriod'))
);
const DashboardOutros = lazy(() =>
  retry(() => import('./components/pages/Dashboard/DashboardOutros'))
);
const DashboardPerfil = lazy(() =>
  retry(() => import('./components/pages/Dashboard/DashboardPerfil'))
);

const Admin = lazy(() => retry(() => import('./components/pages/Admin/Admin')));
const AddUser = lazy(() =>
  retry(() => import('./components/pages/Admin/AddUser'))
);
const UserEdit = lazy(() =>
  retry(() => import('./components/pages/Admin/UserEdit'))
);
const UserView = lazy(() =>
  retry(() => import('./components/pages/Admin/UserView'))
);

const UserConfirm = lazy(() =>
  retry(() => import('./components/pages/UserConfirm'))
);
const ResetPassword = lazy(() =>
  retry(() => import('./components/pages/ResetPassword'))
);
const Verification = lazy(() =>
  retry(() => import('./components/pages/VerificationMessage'))
);

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      )
    }
  />
);

const PrivateRouteHotel = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() && isHotel() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      )
    }
  />
);
const getToFromDateNow = (add = 0) => {
  let newDate = new Date();
  newDate.setDate(newDate.getDate() + add);

  return newDate.toLocaleDateString('pt-BR');
};
const Routes = () => {
  const dashOcuHook = useState({});
  const dashOutrosHook = useState({});
  const dashPerfilHook = useState({});
  const dashAnualHook = useState({});
  const dateHook = useState({from: getToFromDateNow(-10), to: getToFromDateNow(-1)});
  const messageHook = useState({login:""})

  const prepareFirstRoute = () => {
    if (!isAuthenticated()) {
      return <Login />;
    }
    if (isHotel()) {
      return <Redirect to="/dashboard" />;
    }
    return <DashboardPeriod />;
  };

  return (

  <MessagesContext.Provider value={messageHook}>
    <DateContext.Provider value={dateHook}>
      <DashboardAnualContext.Provider value={dashAnualHook}>
        <DashboardPerfilContext.Provider value={dashPerfilHook}>
          <DashboardOutrosContext.Provider value={dashOutrosHook}>
            <DashboardContext.Provider value={dashOcuHook}>
              <Router>
                <Suspense fallback={<div>Loading...</div>}>
                  <Switch>
                    <Route exact path="/">
                      {prepareFirstRoute()}
                    </Route>
                    <Route path="/logout" component={Login} />
                    <Route path="/sign-up" component={SignUp} />
                    <Route path="/verification" component={Verification} />
                    <PrivateRouteHotel
                      path="/dashboard"
                      component={DashboardHotel}
                    />
                    <PrivateRouteHotel
                      path="/dashboard-anual-mensal"
                      component={DashboardPeriod}
                    />
                    <PrivateRoute
                      path="/dashboard-outros"
                      component={DashboardOutros}
                    />
                    <PrivateRoute
                      path="/perfil-hospedes"
                      component={DashboardPerfil}
                    />
                    <PrivateRoute path="/admin" component={Admin} />
                    <PrivateRoute path="/user-view" component={UserView} />
                    <PrivateRoute path="/user-edit" component={UserEdit} />
                    <PrivateRoute path="/add-user" component={AddUser} />
                    {/* <Route path="/dashboard" component={Dashboard} /> */}
                    <Route path="/user-confirm" component={UserConfirm} />
                    <Route path="/reset-password/:token" component={ResetPassword} />
                    <Route path="*" component={() => <h1>Page not found</h1>} />
                  </Switch>
                </Suspense>
              </Router>
            </DashboardContext.Provider>
          </DashboardOutrosContext.Provider>
        </DashboardPerfilContext.Provider>
      </DashboardAnualContext.Provider>
    </DateContext.Provider>
    </MessagesContext.Provider>
  );
};

export default Routes;
