export const TOKEN_KEY = "@turismometro-Token";
export const isAuthenticated = () => {
    let token = localStorage.getItem(TOKEN_KEY);
    if (token !== null) {
        return isJWTValid(token);
    };
    return false;
}
export const isHotel = () => {
    let token = localStorage.getItem(TOKEN_KEY);

    if (token === null) {
        return false;
    };
    if (!isJWTValid(token)) {
        return false;
    }
    let data = parseJwt(token);
    return data.tipoEmpresa === "Hotel";
}
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const setToken = token => {
  localStorage.setItem(TOKEN_KEY, token);
};
export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
};

function isJWTValid(token) {
    let data = parseJwt(token);
    var unixTime = Math.floor(Date.now() / 1000);

    return data.exp > unixTime;
}

function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};