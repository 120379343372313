import React from 'react';
import '../../styles/App.css';

export default function Button(props) {
  const { label, id, className, handleClick } = props;

  return (
    <button id={id} className={className} onClick={handleClick}>
      {label}
    </button>
  );
}